import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import FunctionsPageTemplate from '../components/Functions/FunctionsPageTemplate';
import MainSection from '../components/Functions/MainSection';
import multi from '../assets/images/functions/multi/multi.png';
import multi2x from '../assets/images/functions/multi/multi2x.png';
import multiHeadLeft from '../assets/images/functions/multi/head_left_3x.png';
import multiHeadRight from '../assets/images/functions/multi/head_right_3x.png';
import HeadSection from '../components/Functions/HeadSection';
import PossibilitiesSection from '../components/Functions/PossibilitiesSection';
import first from '../assets/images/functions/multi/1.png';
import first2x from '../assets/images/functions/multi/1_2x.png';
import second from '../assets/images/functions/multi/2.png';
import second2x from '../assets/images/functions/multi/2_2x.png';
import third from '../assets/images/functions/multi/3.png';
import third2x from '../assets/images/functions/multi/3_2x.png';
import fourth from '../assets/images/functions/multi/4.png';
import fourth2x from '../assets/images/functions/multi/4_2x.png';
import ImageContainer from '../components/ImageContainer/ImageContainer';
import BlueSpan from '../components/common/typography/BlueSpan';
import useIsMobile from '../hooks/useIsMobile';

const faqs = [
  {
    question: 'Can I import preset profiles?',
    answer:
      'Yes, you can import a previously exported profile in Octo Browser (a .octo file). A user importing such a profile will receive it with the same fingerprint and cookies as the exported profile.',
  },
  {
    question: 'How do I add tags?',
    answer:
      'You can create and assign new tags to a profile in several ways, e.g., while creating or editing a profile you can click on Edit tags, choose a new tag name, and add a tag.',
  },
].map((item) => ({
  question: <Trans>{item.question}</Trans>,
  answer: <Trans>{item.answer}</Trans>,
}));

const cardsInfo = [
  {
    title: 'No limits',
    description:
      'The number of simultaneously running profiles is limited only by your PC resources.',
    icon: <ImageContainer src={first} srcSet={{ _2x: first2x }} />,
  },
  {
    title: 'Fast profile creation',
    description: 'Create uniquely configured profiles in one click.',
    icon: <ImageContainer src={second} srcSet={{ _2x: second2x }} />,
  },
  {
    title: 'Profile export and import',
    description:
      'Clone, export, and transfer ready profiles with all associated data individually and in bulk.',
    icon: <ImageContainer src={third} srcSet={{ _2x: third2x }} />,
  },
  {
    title: 'Adding tags',
    description:
      'Combine your profiles into groups using tags for quick access to different account types.',
    icon: <ImageContainer src={fourth} srcSet={{ _2x: fourth2x }} />,
  },
];

const MultiAccountingPage = (): JSX.Element => {
  const isSmall = useIsMobile();
  const [titleTransKey, setTransKey] = useState(
    isSmall ? 'Multi-accounting' : 'Multiaccounting'
  );

  useEffect(() => {
    if (isSmall) {
      setTransKey('Multi-accounting');
    } else {
      setTransKey('Multiaccounting');
    }
  }, [isSmall]);

  return (
    <FunctionsPageTemplate faq={faqs}>
      <HeadSection
        description="Every Octo Browser profile has a unique browser fingerprint of a real device, which allows you to control thousands of accounts on one device."
        leftBgImage={multiHeadLeft}
        rightBgImage={multiHeadRight}
        title={titleTransKey}
      />
      <MainSection
        description="Minimize identification risks and possible account overlapping. Allocate risks of possible account loss when working with affiliate marketing and e-commerce."
        image={multi}
        image2x={multi2x}
        title={
          <Trans i18nKey="Risk allocation">
            Risk <BlueSpan>allocation</BlueSpan>
          </Trans>
        }
      />
      <PossibilitiesSection
        cards={cardsInfo}
        title={
          <Trans i18nKey="Multi-accounting features">
            Multi-accounting <BlueSpan>features</BlueSpan>
          </Trans>
        }
      />
    </FunctionsPageTemplate>
  );
};

export default MultiAccountingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["multiAccountingPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
